@import url('https://fonts.googleapis.com/css2?family=Bungee+Hairline&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');

@import './styles/variables.scss';
@import './styles/mixins.scss';

* {
    padding: 0;
    box-sizing: border-box;
    margin: 0;
  }

  .route{
    position: absolute;
    padding: 80px 22px;
    width: 100%;
    height: 100%;
    margin: auto;
  }

  body{
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
    color: $primary;
    background-color: $primary-bg;
    
  }

  h1{
    font-size: 32px;
  }
  
  h1, h2, h3, h4, h5, h6{
    font-weight: 600;
  }

  a {
    text-decoration: none;
    color: #fff;
  }

  li {
    list-style-type: none;
  }