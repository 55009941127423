@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.home{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    .maintenance{
        display: contents;
        p{
            margin: auto;
            width: fit-content;
            border-radius: 10px;
            font-weight: medium;
            font-family: 'Share Tech Mono', monospace;
            border: 1px solid $primary;
            box-shadow: 0 0 5px #000,
                    0 0 2px $tertiary,
                    0 0 10px lighten(#000, 50%),
                    0 0 18px lighten(#000, 30%),
                    inset 0 0 2px $tertiary; 
            padding: 15px;
            background-color: $primary-bg+f5;
            opacity: 0;
            animation: fadeIn 2s 1s forwards;
        }
    }
}