@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.footer{
    &__banner{
        position: fixed;
        width: 250px;
        bottom: 20px;
        right: -80px;
        @include mq(tablet){
            width: 300px;
            right: -100px;
        }
        @include mq(desktop){
            width: 400px;
            right: -150px;
        }
        opacity: 0;
            animation: fadeIn 2s 3s forwards;
    }
}