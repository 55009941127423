@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.header{
    &__banner{
        position: fixed;
        width: 250px;
        top: 20px;
        left: -100px;
        @include mq(tablet){
            width: 300px;
            left: -150px;
        }
        @include mq(desktop){
            width: 400px;
            left: -200px;
        }
        opacity: 0;
            animation: fadeIn 2s 2s forwards;
    }
}