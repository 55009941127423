// Colors
$primary: #000; 
$secondary: #F30125;
$tertiary: #C9D1D9;

// Backgrounds
$primary-bg: #f8f2f2;
$secondary-bg: #161B22;
$tertiary-bg: #21262D;

// font-weight
$light: 200;
$regular: 400;
$medium: 600;

// Breakpoints
$mobile: 375px;
$tablet: 576px;
$laptop: 992px;
$desktop: 1580px;

$breakpoints: (
  mobile: $mobile,
  tablet: $tablet,
  laptop: $laptop,
  desktop: $desktop
);