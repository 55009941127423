#tsparticles{
    width: 0px !important;
    height: 0px !important;
    canvas{
        position: fixed;
        z-index: -2;
            opacity: 0;
            animation: fadeIn 2s 0s forwards;
            display: inherit;
    }
}