// Breakpoints
@mixin mq($mq-breakpoint, $mq-breakpoints: $breakpoints) {

    @if map-has-key($mq-breakpoints, $mq-breakpoint) {
      $mq-breakpoint: map-get($mq-breakpoints, $mq-breakpoint);
    }
    
    @media screen and (min-width: #{$mq-breakpoint}) {
      @content;
    }
}

// Shadows
@mixin shadow {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

@mixin shadow-hover {
box-shadow: 0px 0px 10px rgba(darken($primary, 50%), .8);
}